import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useMedicationList() {
  const rxMedicationListTable = ref([])
  const ndcMedicationListTable = ref([])
  const scriptList = ref([])
  const timingList = ref([])
  const immunizationList = ref([])
  const patientImmunizationList = ref([])
  const prescriberList = ref([])
  const medTimeList = ref([])
  const selectedItem = ref()
  const drugInteractionAlert = ref()
  const errorMessages = ref([])
  const treatmentList = ref([])

  const scriptTypeList = ['Profile', 'New', 'Discontinued', 'Refill', 'Emergency']
  const billingTypeList = ['County', 'Federal', 'ICE', 'DOC']
  const distributionTypeList = ['Regular', 'Keep On Person', 'Stat', 'Stat +', 'HomeMed', 'PRN']

  const medTimeTableColumns = [
    { text: 'TIME', value: 'time' },
    { text: 'STATUS', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const scriptTableColumns = [
    { text: 'PATIENT', value: 'patient.displayName', width: '10%' },
    { text: 'PATIENT ID', value: 'patient.jacketId', width: '10%' },
    { text: 'DRUG NAME', value: 'drugName', width: '10%' },
    { text: 'STRENGTH', value: 'drugStrength', width: '10%' },
    { text: 'START', value: 'distributionSchedule.startDate', width: '10%' },
    { text: 'STOP', value: 'distributionSchedule.stopDate', width: '10%' },
    { text: 'MEDTIMES', value: 'distributionSchedule.medTimes', width: '10%' },
    { text: 'PRESCRIBER', value: 'prescriber.displayName', width: '10%' },
    { text: 'PROBLEM', value: 'patientProblem.problem.label', width: '10%' },
    { text: 'STATUS', value: 'isActive', width: '10%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const patientScriptTableColumns = [
    { text: 'DRUG NAME', value: 'drugName', width: 175 },
    { text: 'STRENGTH', value: 'drugStrength' },
    { text: 'START', value: 'distributionSchedule.startDate', width: 175 },
    { text: 'STOP', value: 'distributionSchedule.stopDate', width: 175 },
    { text: 'MEDTIMES', value: 'distributionSchedule.medTimes' },
    { text: 'PRESCRIBER', value: 'prescriber.displayName' },
    { text: 'PROBLEM', value: 'patientProblem.problem.label' },
    { text: 'DIRECTIONS', value: 'directions', width: 175 },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const patientTreatmentTableColumns = [
    { text: 'START', value: 'distributionSchedule.startDate', width: 125 },
    { text: 'STOP', value: 'distributionSchedule.stopDate', width: 125 },
    { text: 'MEDTIMES', value: 'distributionSchedule.medTimes' },
    { text: 'PRESCRIBER', value: 'prescriber.displayName' },
    { text: 'DIRECTIONS', value: 'directions', width: 175 },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const immunizationColumns = [
    { text: 'NAME', value: 'useUnitGenericName', width: 125 },
    { text: 'PROP', value: 'useUnitPropName', width: 125 },
    { text: 'GENERIC', value: 'vaccine_name', width: 125 },
    { text: 'MANUFACTURER', value: 'useUnitLabelerName', width: 125 },
    { text: 'DOSE', value: 'dose' },
    { text: 'NDC', value: 'ndC11' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const patientImmunizationColumns = [
    { text: 'NAME', value: 'vaccinetype', width: 200 },
    { text: 'MANUFACTURER', value: 'manufacturer', width: 150 },
    { text: 'RECORDED', value: 'recordedDate' },
    { text: 'SITE', value: 'administrationSite' },
    { text: 'LOT', value: 'lotNumber' },
    { text: 'NOTES', value: 'notes' },
    { text: 'ACTIVE', value: 'isActive' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableColumns = [{ text: 'CODE', value: 'code' }]

  const rxType = ['SCD', 'SBD']

  const searchQuery = ref('')
  const doseQuery = ref(null)
  const searchScript = ref('')
  const searchPatientTreatment = ref('')
  const searchTreatment = ref('')
  const searchPatientScript = ref('')
  const searchTime = ref('')
  const statusFilter = ref(null)
  const totalMedicationListTable = ref(0)
  const totalNDCMedications = ref([])
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: 5,
  })
  const medicationTotalLocal = ref([])
  const selectedRow = ref([])
  const selectedTime = ref([])
  const selectedImmunization = ref([])

  // new stock med
  const postStockMed = NewStockMedDTO => {
    console.log(`reportWaste usemedicationservice for MedWasteDTO: ${JSON.stringify(NewStockMedDTO)}`)
    store
      .dispatch('app-medication/postNewStockMed', NewStockMedDTO)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')

        // //console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  // refill medication
  const refillThisMed = MedId => {
    // //console.log(`refill usemedicationservice for medi id: ${MedId}`)
    store
      .dispatch('app-medication/refillMedication', MedId)
      .then(response => {})
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')

        errorMessages.value = error.response.data.error
      })
  }

  // fetch Site Med Times
  const fetchAllStockMeds = () => {
    store
      .dispatch('app-medication/fetchStockMeds')
      .then(response => {
        scriptList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        // //console.log(error)

        // TODO: Next Update - Show notification
        console.error(`Oops, Unable to Fetch! due to ${error}`)

        // //console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  // fetch Site Med Times
  const fetchSiteMedTimes = () => {
    store
      .dispatch('app-medication/fetchSiteMedTimes')
      .then(response => {
        medTimeList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchSiteMedTimes()
  })

  const postSiteMedTime = siteMedTimeDTO => {
    store
      .dispatch('app-medication/postSiteMedTime', siteMedTimeDTO)
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    postSiteMedTime()
  })

  // fetch RXnorm Data
  const fetchRXNormItems = () => {
    const searchText = doseQuery.value != null ? searchQuery.value + ' ' + doseQuery.value : searchQuery.value

    store
      .dispatch('app-medication/fetchRXNormItems', searchText)
      .then(response => {
        rxMedicationListTable.value = response.drugGroup.conceptGroup

        rxMedicationListTable.value = rxMedicationListTable.value.filter(drugGroup => rxType.includes(drugGroup.tty))

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    selectedRow.value = []
    fetchRXNormItems()
  })

  const fetchOpenFDAItems = rxcui => {
    ndcMedicationListTable.value = []
    store
      .dispatch('app-medication/fetchOpenFDAItems', rxcui, { root: true })
      .then(response => {
        ndcMedicationListTable.value = response
        totalNDCMedications.value = ndcMedicationListTable.value
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    fetchOpenFDAItems()
  })

  // fetch Scripts
  const fetchSiteScripts = () => {
    store
      .dispatch('app-medication/fetchSiteScripts')
      .then(response => {
        scriptList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([searchScript], () => {
    loading.value = true
    selectedItem.value = []
    fetchSiteScripts()
  })

  const fetchPatientScripts = patientId => {
    store
      .dispatch('app-medication/fetchSitePatientScripts', patientId)
      .then(response => {
        scriptList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([searchPatientScript], () => {
    loading.value = true
    selectedItem.value = []
    fetchPatientScripts()
  })

  const postPatientScript = patientScriptDTO => {
    store
      .dispatch('app-medication/postPatientScript', patientScriptDTO)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    selectedItem.value = []
    postPatientScript()
  })

  // Site Prescribers
  const fetchSitePrescribers = () => {
    store
      .dispatch('app-medication/fetchSitePrescribers', { root: true })
      .then(response => {
        prescriberList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    fetchSitePrescribers()
  })

  // Site Treatments
  const fetchSiteTreatments = () => {
    store
      .dispatch('app-medication/fetchSiteTreatments')
      .then(response => {
        treatmentList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([searchTreatment], () => {
    loading.value = true
    selectedItem.value = []
    fetchSiteTreatments()
  })

  const fetchPatientTreatments = patientId => {
    store
      .dispatch('app-medication/fetchSitePatientTreatments', patientId)
      .then(response => {
        treatmentList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([searchPatientTreatment], () => {
    loading.value = true
    selectedItem.value = []
    fetchPatientTreatments()
  })

  const postPatientTreatment = patientTreatmentDTO => {
    store
      .dispatch('app-medication/postPatientTreatment', patientTreatmentDTO)
      .then(response => {
        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Post!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    selectedItem.value = []
    postPatientTreatment()
  })

  // Script Timings
  const fetchScriptTimings = () => {
    store
      .dispatch('app-medication/fetchScriptTimings')
      .then(response => {
        timingList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchScriptTimings()
  })

  const fetchScriptTiming = scriptTimingId => {
    store
      .dispatch('app-medication/fetchScriptTiming', scriptTimingId)
      .then(response => {
        timingList.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchScriptTiming()
  })

  // Immunizations
  const fetchImmunizations = () => {
    loading.value = true
    store
      .dispatch('app-medication/fetchImmunizations')
      .then(response => {
        immunizationList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchImmunizations()
  })

  const fetchImmunizationFile = () => {
    loading.value = true
    store
      .dispatch('app-medication/fetchImmunizationFile')
      .then(response => {
        console.log(response)
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchImmunizationFile()
  })

  // Patient Immunizations
  const fetchPatientImmunizations = patientId => {
    loading.value = true
    store
      .dispatch('app-medication/fetchPatientImmunizations', patientId)
      .then(response => {
        console.log('Patient Immunizations', response)
        patientImmunizationList.value = response

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    fetchPatientImmunizations()
  })

  const postPatientImmunization = patientImmunizationDTO => {
    store
      .dispatch('app-medication/postPatientImmunization', patientImmunizationDTO)
      .then(response => {
        console.log('Posted Immunization', response)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)

        // TODO: Next Update - Show notification
        console.error('Oops, Unable to Fetch!')
        console.log('error :>> ', error.response)
        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    loading.value = true
    postPatientImmunization()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    rxMedicationListTable,
    ndcMedicationListTable,
    tableColumns,
    searchQuery,
    doseQuery,
    scriptTypeList,
    billingTypeList,
    distributionTypeList,
    searchScript,
    statusFilter,
    totalMedicationListTable,
    loading,
    options,
    rxType,
    medicationTotalLocal,
    totalNDCMedications,
    selectedRow,
    selectedItem,
    selectedTime,
    searchPatientScript,
    searchTime,
    medTimeTableColumns,
    fetchRXNormItems,
    fetchOpenFDAItems,

    scriptTableColumns,
    scriptList,
    fetchSiteScripts,
    postPatientScript,
    fetchPatientScripts,
    patientScriptTableColumns,

    medTimeList,
    fetchSiteMedTimes,

    postSiteMedTime,

    fetchSitePrescribers,
    prescriberList,
    drugInteractionAlert,

    treatmentList,
    searchTreatment,
    searchPatientTreatment,
    errorMessages,
    fetchPatientTreatments,
    fetchSiteTreatments,
    postPatientTreatment,
    patientTreatmentTableColumns,

    fetchScriptTiming,
    fetchScriptTimings,
    timingList,

    immunizationList,
    fetchImmunizations,
    selectedImmunization,
    immunizationColumns,
    fetchImmunizationFile,

    patientImmunizationList,
    fetchPatientImmunizations,
    postPatientImmunization,
    patientImmunizationColumns,
    refillThisMed,

    fetchAllStockMeds,
    postStockMed,
  }
}
