import variables from '@/variables'
import ApiConfig from './ApiConfig'
import {
  MedicationClient_V1,
  OpenFDAItemClient_V1,
  PatientScriptClient_V1,
  PatientTreatmentClient_V1,
  RXNormItemClient_V1,
  ScriptTimingClient_V1,
  SiteMedTimeClient_V1,
  UserClient_V1,
  CDCImmunizationsClient_V1,
  PatientImmunizationClient_V1,
} from './generated/api.generated.v1'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // stock med
    postNewStockMed(context, payload) {
      const api = new MedicationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postSiteMedication(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // waste
    reportMedWaste(context, payload) {
      const api = new MedicationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log(`medication service reportMedWaste for script id of: ${payload}`)

      return new Promise((resolve, reject) => {
        api
          .processMedicationWaste(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // refill
    refillStockMedId(context, payload) {
      const api = new MedicationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log(`medication service refillStockMedId for script id of: ${payload}`)

      return new Promise((resolve, reject) => {
        api
          .refillStockMed(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // refill
    refillMedication(context, payload) {
      const api = new MedicationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      console.log(`medication service refillMedication for script id of: ${payload}`)

      return new Promise((resolve, reject) => {
        api
          .refillMedication(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Get All Stock MEds
    fetchStockMeds() {
      const api = new MedicationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteStockMedications()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // site med times
    fetchSiteMedTimes() {
      const api = new SiteMedTimeClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteMedTimes()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postSiteMedTime(context, payload) {
      const api = new SiteMedTimeClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postSiteMedTime(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // RXNorm
    fetchRXNormItems(context, payload) {
      const api = new RXNormItemClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getRXNormItems(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Open FDA
    fetchOpenFDAItems(context, payload) {
      const api = new OpenFDAItemClient_V1(new ApiConfig(), variables.INTERNAL_API)
      const searchField = 'openfda.rxcui'

      return new Promise((resolve, reject) => {
        api
          .getOpenFDAItems(searchField, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Patient Script

    fetchPatientScript(context, payload) {
      const api = new PatientScriptClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientScript(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSitePatientScripts(context, payload) {
      const api = new PatientScriptClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSitePatientScripts(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteScripts() {
      const api = new PatientScriptClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteScripts()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientScript(context, payload) {
      const api = new PatientScriptClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientScript(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Site Prescribers
    fetchSitePrescribers() {
      const api = new UserClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSitePrescribers()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Patient Treatment

    fetchPatientTreatment(context, payload) {
      const api = new PatientTreatmentClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientTreatment(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSitePatientTreatments(context, payload) {
      const api = new PatientTreatmentClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSitePatientTreatments(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSiteTreatments() {
      const api = new PatientTreatmentClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getSiteTreatments()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientTreatment(context, payload) {
      const api = new PatientTreatmentClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientTreatment(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Script Timings
    fetchScriptTimings() {
      const api = new ScriptTimingClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getScriptTimings()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchScriptTiming(context, scriptTimingId) {
      const api = new ScriptTimingClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getScriptTiming(scriptTimingId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Immunizations
    fetchImmunizations() {
      const api = new CDCImmunizationsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getCDCImmunizations()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchImmunizationFile() {
      const api = new CDCImmunizationsClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .cDCImmunizationFile()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Patient Immunizations
    fetchPatientImmunizations(context, patientId) {
      const api = new PatientImmunizationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getPatientImmunizations(patientId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    postPatientImmunization(context, patientImmunization) {
      const api = new PatientImmunizationClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postPatientImmunization(patientImmunization)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
